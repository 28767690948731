@media print {
	.print {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
		-webkit-print-color-adjust: exact !important;
		print-color-adjust: exact !important;
		background: #ffffff;
		overflow: hidden !important;
	}

	header,
	footer,
	thead,
	tfoot {
		display: none !important;
	}

	::-webkit-scrollbar {
		display: none !important;
	}

	.item {
		break-inside: avoid-column !important;
	}

	.item:nth-of-type(2) {
		break-inside: avoid-column !important;
	}

	.printHidden {
		display: none !important;
	}
}
