#gallery {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
}

.gallery-content {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	gap: 0.5rem;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
}

.gallery-image-wrapper {
	min-width: 40%;
}

.gallery-image {
	max-height: 9rem;
	-webkit-margin-start: auto;
	margin-inline-start: auto;
	-webkit-margin-end: auto;
	margin-inline-end: auto;
}

.gallery-image:hover {
	cursor: pointer;
	opacity: 0.8;
}

.icon {
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	overflow: hidden;
}

.icon:hover {
	cursor: pointer;
	color: #e61e28;
	background-color: #dedee0;
}

.modal {
	position: fixed;
	z-index: 1;
	padding-top: 10rem;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
	margin: auto;
	display: block;
	width: 80%;
	max-width: 50rem;
}

.modal-content {
	-webkit-animation-name: zoom;
	-webkit-animation-duration: 0.5s;
	animation-name: zoom;
	animation-duration: 0.5s;
}

@-webkit-keyframes zoom {
	from {
		-webkit-transform: scale(0);
	}
	to {
		-webkit-transform: scale(1);
	}
}

@keyframes zoom {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}

.close {
	position: absolute;
	top: 1rem;
	right: 2rem;
	color: #f1f1f1;
	font-size: 2.75rem;
	font-weight: bold;
	transition: 0.3s;
}

.close:hover {
	cursor: pointer;
}

@media only screen and (max-width: 700px) {
	.modal-content {
		width: 100%;
	}
}
